.schedule-card-dots-holder {
  width: 350px;
  height: 475px;
  position: relative;
  top: 10%;
  background-image: radial-gradient(
    circle at 5px 5px,
    #717171 2px,
    transparent 0
  );
  background-size: 25px 25px;
}


@media (max-width: 576px) and (orientation: landscape) {
  .schedule-card-dots-holder {
    width: 375px;
    height: 500px;
    position: relative;
  }
}

@media (min-width: 576px) and (orientation: portrait) {
  .schedule-card-dots-holder {
    width: 375px;
    height: 500px;
  }
}

@media (min-width: 576px) and (orientation: landscape) {
  .schedule-card-dots-holder {
    width: 575px;
    height: 625px;
  }
}

@media (min-width: 768px) and (orientation: portrait) {
  .schedule-card-dots-holder {
    width: 500px;
    height: 575px;
  }
}

@media (min-width: 768px) and (orientation: landscape) {
  .schedule-card-dots-holder {
    width: 475px;
    height: 550px;
  }
}

@media (min-width: 922px) and (orientation: portrait) {
  .schedule-card-dots-holder {
    width: 575px;
    height: 600px;
  }
}

@media (min-width: 922px) and (orientation: landscape) {
  .schedule-card-dots-holder {
    width: 475px;
    height: 550px;
  }
}

@media (min-width: 1200px) and (orientation: portrait) {
  .schedule-card-dots-holder {
    width: 375px;
    height: 500px;
  }
}

@media (min-width: 1200px) and (orientation: landscape) {
  .schedule-card-dots-holder {
    width: 525px;
    height: 575px;
  }
}


@media (min-width: 1400px) and (orientation: portrait) {
  .schedule-card-dots-holder {
    width: 375px;
    height: 500px;
  }
}

@media (min-width: 1400px) and (orientation: landscape) {
  .schedule-card-dots-holder {
    width: 575px;
    height: 650px;
  }
}

  
  .schedule-dark-overlay {
    position: absolute;
    top: 50%;
    left: 48%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 400px;
    background-color: black;
    opacity: 0.8;
    border-radius: 10px;
  }


  @media (max-width: 576px) and (orientation: landscape) {
    .schedule-dark-overlay {
      width: 300px;
      height: 400px;
    }
  }

  @media (min-width: 576px) and (orientation: portrait) {
    .schedule-dark-overlay {
      width: 300px;
      height: 400px;
    }
  }
  
  @media (min-width: 576px) and (orientation: landscape) {
    .schedule-dark-overlay {
      width: 475px;
      height: 525px;
    }
  }
  
  @media (min-width: 768px) and (orientation: portrait) {
    .schedule-dark-overlay {
      width: 400px;
      height: 460px;

    }
  }
  
  @media (min-width: 768px) and (orientation: landscape) {
    .schedule-dark-overlay {
      width: 400px;
      height: 460px;
    }
    
  }

  @media (min-width: 922px) and (orientation: portrait) {
    .schedule-dark-overlay {
      width: 500px;
      height: 525px;
    }
  }
  
  @media (min-width: 922px) and (orientation: landscape) {
    .schedule-dark-overlay {
      width: 400px;
      height: 460px;
    }
  }

  @media (min-width: 1200px) and (orientation: portrait) {
    .schedule-dark-overlay {
      width: 300px;
      height: 400px;
    }
  }
  
  @media (min-width: 1200px) and (orientation: landscape) {
    .schedule-dark-overlay {
      width: 475px;
      height: 500px;
    }
  }
  

  @media (min-width: 1400px) and (orientation: portrait) {
    .schedule-dark-overlay {
      width: 300px;
      height: 400px;
    }
  }
  
  @media (min-width: 1400px) and (orientation: landscape) {
    .schedule-dark-overlay {
      width: 475px;
      height: 525px;
    }
  }
  

  
  .schedule-dark-overlay-date {
    font-family: "Montserrat", sans-serif;
    color: red;
    font-size: 1.45rem;
  }


  @media (max-width: 576px) and (orientation: landscape) {
    .schedule-dark-overlay-date {
      font-size: 1.5625rem;
    }
  }

  @media (min-width: 576px) and (orientation: portrait) {
    .schedule-dark-overlay-date {
      font-size: 1.5625rem;
    }
  }
  
  @media (min-width: 576px) and (orientation: landscape) {
    .schedule-dark-overlay-date {
      font-size: 1.5625rem;
    }
  }
  
  @media (min-width: 768px) and (orientation: portrait) {
    .schedule-dark-overlay-date {
      font-family: "Montserrat", sans-serif;
      color: red;
      font-size: 1.45rem;
    }
  }
  
  @media (min-width: 768px) and (orientation: landscape) {
    .schedule-dark-overlay-date {
      font-size: 1.45rem;
    }
  }

  @media (min-width: 922px) and (orientation: portrait) {
    .schedule-dark-overlay-date {
      font-size: 1.5625rem;
    }
  }
  
  @media (min-width: 922px) and (orientation: landscape) {
    .schedule-dark-overlay-date {
      font-size: 1.5625rem;
    }
  }

  @media (min-width: 1200px) and (orientation: portrait) {
    .schedule-dark-overlay-date {
      font-size: 1.5625rem;
    }
  }
  
  @media (min-width: 1200px) and (orientation: landscape) {
    .schedule-dark-overlay-date {
      font-size: 1.5625rem;
    }
  }
  

  @media (min-width: 1400px) and (orientation: portrait) {
    .schedule-dark-overlay-date {
      font-size: 1.5625rem;
    }
  }
  
  @media (min-width: 1400px) and (orientation: landscape) {
    .schedule-dark-overlay-date {
      font-size: 1.5625rem;
    }
  }
  
  .schedule-dark-overlay-slot {
    font-family: "Montserrat", sans-serif;
    font-size: 0.77rem;
    color: white;
  }

  @media (max-width: 576px) and (orientation: landscape) {
    .schedule-dark-overlay-slot {
      font-size: 1.25rem;
    }
  }

  @media (min-width: 576px) and (orientation: portrait) {
    .schedule-dark-overlay-slot {
      font-size: 1.25rem;
    }
  }
  
  @media (min-width: 576px) and (orientation: landscape) {
    .schedule-dark-overlay-slot {
      font-size: 1.25rem;
    }
  }
  
  @media (min-width: 768px) and (orientation: portrait) {
    .schedule-dark-overlay-slot {
      font-family: "Montserrat", sans-serif;
      font-size: 1rem;
      color: white;
    }
  }
  
  @media (min-width: 768px) and (orientation: landscape) {
    .schedule-dark-overlay-slot {
      font-size: 1rem;
    }
  }

  @media (min-width: 922px) and (orientation: portrait) {
    .schedule-dark-overlay-slot {
      font-size: 1.25rem;
    }
  }
  
  @media (min-width: 922px) and (orientation: landscape) {
    .schedule-dark-overlay-slot {
      font-size: 1rem;
    }
  }

  @media (min-width: 1200px) and (orientation: portrait) {
    .schedule-dark-overlay-slot {
      font-size: 1.25rem;
    }
  }
  
  @media (min-width: 1200px) and (orientation: landscape) {
    .schedule-dark-overlay-slot {
      font-size: 1.25rem;
    }
  }
  

  @media (min-width: 1400px) and (orientation: portrait) {
    .schedule-dark-overlay-slot {
      font-size: 1.25rem;
    }
  }
  
  @media (min-width: 1400px) and (orientation: landscape) {
    .schedule-dark-overlay-slot {
      font-size: 1.25rem;
    }
  }
  





  /* .schedule-dark-overlay-line-accent {
    height: 50px;
    width: 5px;
    background-color: red;
    border-radius: 30px;
    transform: rotate(45deg);
    position: relative;
    right: 23px;
    bottom: 25px;
  } */
  
  .schedule-accent-circle {
    height: 45px;
    width: 45px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    top: -5%;
    left: 89%;
  }

  @media (max-width: 576px) and (orientation: landscape) {
    .schedule-accent-circle {
      height: 50px;
      width: 50px;
      background-color: red;
      border-radius: 50%;
      position: absolute;
      top: -6%;
      left: 91%;
    }
  }

  @media (min-width: 576px) and (orientation: portrait) {
    .schedule-accent-circle {
      height: 50px;
      width: 50px;
      background-color: red;
      border-radius: 50%;
      position: absolute;
      top: -6%;
      left: 91%;
    }
  }
  
  @media (min-width: 576px) and (orientation: landscape) {
    .schedule-accent-circle {
      height: 50px;
      width: 50px;
      background-color: red;
      border-radius: 50%;
      position: absolute;
      top: -6%;
      left: 91%;
    }
  }
  
  @media (min-width: 768px) and (orientation: portrait) {
    .schedule-accent-circle {
      height: 45px;
      width: 45px;
      background-color: red;
      border-radius: 50%;
      position: absolute;
      top: -5%;
      left: 89%;
    }
  }
  
  @media (min-width: 768px) and (orientation: landscape) {
    .schedule-accent-circle {
      height: 45px;
      width: 45px;
      background-color: red;
      border-radius: 50%;
      position: absolute;
      top: -6%;
      left: 91%;
    }
  }

  @media (min-width: 922px) and (orientation: portrait) {
    .schedule-accent-circle {
      height: 50px;
      width: 50px;
      background-color: red;
      border-radius: 50%;
      position: absolute;
      top: -6%;
      left: 91%;
    }
  }
  
  @media (min-width: 922px) and (orientation: landscape) {
    .schedule-accent-circle {
      height: 50px;
      width: 50px;
      background-color: red;
      border-radius: 50%;
      position: absolute;
      top: -6%;
      left: 91%;
    }
  }

  @media (min-width: 1200px) and (orientation: portrait) {
    .schedule-accent-circle {
      height: 50px;
      width: 50px;
      background-color: red;
      border-radius: 50%;
      position: absolute;
      top: -6%;
      left: 91%;
    }
  }
  
  @media (min-width: 1200px) and (orientation: landscape) {
    .schedule-accent-circle {
      height: 50px;
      width: 50px;
      background-color: red;
      border-radius: 50%;
      position: absolute;
      top: -6%;
      left: 91%;
    }
  }
  

  @media (min-width: 1400px) and (orientation: portrait) {
    .schedule-accent-circle {
      height: 50px;
      width: 50px;
      background-color: red;
      border-radius: 50%;
      position: absolute;
      top: -6%;
      left: 91%;
    }
  }
  
  @media (min-width: 1400px) and (orientation: landscape) {
    .schedule-accent-circle {
      height: 50px;
      width: 50px;
      background-color: red;
      border-radius: 50%;
      position: absolute;
      top: -6%;
      left: 91%;
    }
  }
  

  