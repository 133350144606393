.footer-section{
    height: 100%;
    background-color: black;
    padding-top: 1em;
}


.footer-section-grey-divider{
    height: 2px;
    background-color: #535353;
    width: 90%;
    margin: 0 auto;
}

@media (max-width: 576px) and (orientation: landscape) {
    .footer-section-grey-divider{
    }
  }

  @media (min-width: 576px) and (orientation: portrait) {
    .footer-section-grey-divider{
    }
  }
  
  @media (min-width: 576px) and (orientation: landscape) {
    .footer-section-grey-divider{
    }
  }
  
  @media (min-width: 768px) and (orientation: portrait) {
    .footer-section-grey-divider{
    }
  }
  
  @media (min-width: 768px) and (orientation: landscape) {
    .footer-section-grey-divider{
    }
  }

  @media (min-width: 922px) and (orientation: portrait) {
    .footer-section-grey-divider{
    }
  }
  
  @media (min-width: 922px) and (orientation: landscape) {
    .footer-section-grey-divider{
    }
  }

  @media (min-width: 1200px) and (orientation: portrait) {
    .footer-section-grey-divider{
    } 
  }
  
  @media (min-width: 1200px) and (orientation: landscape) {
    .footer-section-grey-divider{
    }
  }
  

  @media (min-width: 1400px) and (orientation: portrait) {
    .footer-section-grey-divider{

    }
  }
  
  @media (min-width: 1400px) and (orientation: landscape) {
    .footer-section-grey-divider{

    }
  } 

.footer-section-second-row{
    display: flex; 
    justify-content: space-around;
    max-width: 99%;
    margin: 0 auto;
    padding: 3em 0em 3em 0;
    position: relative;
}

/* @media (max-width: 576px) and (orientation: landscape) {
    .footer-section-second-row{
    }
  }

  @media (min-width: 576px) and (orientation: portrait) {
    .footer-section-second-row{
    }
  }
  
  @media (min-width: 576px) and (orientation: landscape) {
    .footer-section-second-row{
    }
  }
  
  @media (min-width: 768px) and (orientation: portrait) {
    .footer-section-second-row{
    }
  }
  
  @media (min-width: 768px) and (orientation: landscape) {
    .footer-section-second-row{
    }
  }

  @media (min-width: 922px) and (orientation: portrait) {
    .footer-section-second-row{
    }
  }
  
  @media (min-width: 922px) and (orientation: landscape) {
    .footer-section-second-row{
    }
  }

  @media (min-width: 1200px) and (orientation: portrait) {
    .footer-section-second-row{
    }
  }
  
  @media (min-width: 1200px) and (orientation: landscape) {
    .footer-section-second-row{
    }
  }
  

  @media (min-width: 1400px) and (orientation: portrait) {
    .footer-section-second-row{
    }
  }
  
  @media (min-width: 1400px) and (orientation: landscape) {
    .footer-section-second-row{
    }
  } */

/* .footer-section-spartahack-mini-logo{
    background-color: darkgreen;
    position: absolute;
    top : 15%;
    left : 48%;
} */


/* .footer-section-social-media-icons-holder{
    height: 2.5rem;
    background-color: darkgreen;
    display: flex;

} */

.social-media-icons{
  max-width: 4rem;
  height: 2.75rem;
}

.react-icons{
  max-width: 4rem;
  height: 2.75rem;
}

.mlh-logo{
  max-width: 5rem;
  height: 2.75rem;
}

@media (max-width: 576px) and (orientation: landscape) {
  .mlh-logo{
  }
}

@media (min-width: 576px) and (orientation: portrait) {
  .mlh-logo{
  } 
}

@media (min-width: 576px) and (orientation: landscape) {
  .mlh-logo{
  } 
}

@media (min-width: 768px) and (orientation: portrait) {
  .mlh-logo{
    max-width: 5.5rem;
    height: 2.75rem;
  } 
}

@media (min-width: 768px) and (orientation: landscape) {
  .mlh-logo{
    max-width: 5.5rem;
    height: 2.75rem;
  } 
}

@media (min-width: 922px) and (orientation: portrait) {
  .mlh-logo{
    max-width: 90%;
  } 
}

@media (min-width: 922px) and (orientation: landscape) {
  .mlh-logo{
  } 
}

@media (min-width: 1200px) and (orientation: portrait) {
  .mlh-logo{
  } 
}

@media (min-width: 1200px) and (orientation: landscape) {
  .mlh-logo{
  } 
}


@media (min-width: 1400px) and (orientation: portrait) {
  .mlh-logo{
  } 
}

@media (min-width: 1400px) and (orientation: landscape) {
  .mlh-logo{
    max-width: 10rem;
    height: 4rem;
  }
}

/* .footer-section-thanks-for-visiting {
    font-family: "Montserrat", sans-serif;
    font-size: 0.8rem;
    font-weight: bold;
    color: white;
} */

/* @media (max-width: 576px) and (orientation: landscape) {
    .footer-section-thanks-for-visiting {
    }
  }

  @media (min-width: 576px) and (orientation: portrait) {
    .footer-section-thanks-for-visiting {
    }
  }
  
  @media (min-width: 576px) and (orientation: landscape) {
    .footer-section-thanks-for-visiting {
    }
  }
  
  @media (min-width: 768px) and (orientation: portrait) {
    .footer-section-thanks-for-visiting {
    }
  }
  
  @media (min-width: 768px) and (orientation: landscape) {
    .footer-section-thanks-for-visiting {
    }
  }

  @media (min-width: 922px) and (orientation: portrait) {
    .footer-section-thanks-for-visiting {
    }
  }
  
  @media (min-width: 922px) and (orientation: landscape) {
    .footer-section-thanks-for-visiting {
    }
  }

  @media (min-width: 1200px) and (orientation: portrait) {
    .footer-section-thanks-for-visiting {
    }
  }
  
  @media (min-width: 1200px) and (orientation: landscape) {
    .footer-section-thanks-for-visiting {
    }
  }
  

  @media (min-width: 1400px) and (orientation: portrait) {
    .footer-section-thanks-for-visiting {
    }
  }
  
  @media (min-width: 1400px) and (orientation: landscape) {
    .footer-section-thanks-for-visiting {
    }
  } */

.footer-section-red-dots{
    width: 96%;
    height: 75px;
    background-image: radial-gradient(
      circle at 5px 5px,
      #C30909 3px,
      transparent 0
    );
    background-size: 25px 25px;
    margin: 0 auto;
} 

@media (max-width: 576px) and (orientation: landscape) {
    .footer-section-red-dots{
    } 
  }

  @media (min-width: 576px) and (orientation: portrait) {
    .footer-section-red-dots{
    } 
  }
  
  @media (min-width: 576px) and (orientation: landscape) {
    .footer-section-red-dots{
    } 
  }
  
  @media (min-width: 768px) and (orientation: portrait) {
    .footer-section-red-dots{
    } 
  }
  
  @media (min-width: 768px) and (orientation: landscape) {
    .footer-section-red-dots{
    } 
  }

  @media (min-width: 922px) and (orientation: portrait) {
    .footer-section-red-dots{
      max-width: 90%;
    } 
  }
  
  @media (min-width: 922px) and (orientation: landscape) {
    .footer-section-red-dots{
    } 
  }

  @media (min-width: 1200px) and (orientation: portrait) {
    .footer-section-red-dots{
    } 
  }
  
  @media (min-width: 1200px) and (orientation: landscape) {
    .footer-section-red-dots{
    } 
  }
  

  @media (min-width: 1400px) and (orientation: portrait) {
    .footer-section-red-dots{
    } 
  }
  
  @media (min-width: 1400px) and (orientation: landscape) {
    .footer-section-red-dots{
      width: 95%;
      max-width: 2000px;
    } 
  }
