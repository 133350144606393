.faq-section {
    position: relative;
    background-color: black;
  }
  
  .faq-header-underline-container {
    position: relative;
  }
  
  .faq-header-text {
    color: white;
    font-size: 2.8125rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
  }


  @media (max-width: 576px) and (orientation: landscape) {
    .faq-header-text {
      font-size: 2.8125rem;
    }
  }

  @media (min-width: 576px) and (orientation: portrait) {
    .faq-header-text {
      font-size: 2.8125rem;
    }
  }
  
  @media (min-width: 576px) and (orientation: landscape) {
    .faq-header-text {
      font-size: 2.8125rem;
    }
  }
  
  @media (min-width: 768px) and (orientation: portrait) {
    .faq-header-text {
      font-size: 3.4375rem;
    }
  }
  
  @media (min-width: 768px) and (orientation: landscape) {
    .faq-header-text {
      font-size: 3.4375rem;
    }
  }

  @media (min-width: 922px) and (orientation: portrait) {
    .faq-header-textt {
      font-size: 4.6875rem;
    }
  }
  
  @media (min-width: 922px) and (orientation: landscape) {
    .faq-header-text {
      font-size: 4.6875rem;
    }
  }

  @media (min-width: 1200px) and (orientation: portrait) {
    .faq-header-text {
      font-size: 4.6875rem;
    }
  }
  
  @media (min-width: 1200px) and (orientation: landscape) {
    .faq-header-text {
      font-size: 4.6875rem;
    }
  }
  

  @media (min-width: 1400px) and (orientation: portrait) {
    .faq-header-text {
      font-size: 4.6875rem;
    }
  }
  
  @media (min-width: 1400px) and (orientation: landscape) {
    .faq-header-text {
      font-size: 4.6875rem;
    }
  }
  
  .faq-header-text-underline {
    background-color: red;
    position: absolute;
    bottom: 5%;
    left: 4%;
    height: 3px;
    width: 90px;
  }


  @media (max-width: 576px) and (orientation: landscape) {
    .faq-header-text-underline {
        background-color: red;
        position: absolute;
        bottom: 5%;
        left: 4%;
        height: 3px;
        width: 115px;
      }
  }

  @media (min-width: 576px) and (orientation: portrait) {
    .faq-header-text-underline {
        background-color: red;
        position: absolute;
        bottom: 5%;
        left: 4%;
        height: 3px;
        width: 115px;
      }
  }
  
  @media (min-width: 576px) and (orientation: landscape) {
    .faq-header-text-underline {
        background-color: red;
        position: absolute;
        bottom: 5%;
        left: 4%;
        height: 3px;
        width: 85px;
      }
  }
  
  @media (min-width: 768px) and (orientation: portrait) {
    .faq-header-text-underline {
        background-color: red;
        position: absolute;
        bottom: 5%;
        left: 4%;
        height: 3px;
        width: 100px;
      }
  }
  
  @media (min-width: 768px) and (orientation: landscape) {
    .faq-header-text-underline {
        background-color: red;
        position: absolute;
        bottom: 5%;
        left: 4%;
        height: 3px;
        width: 100px;
      }
  }

  @media (min-width: 922px) and (orientation: portrait) {
    .faq-header-text-underline {
        background-color: red;
        position: absolute;
        bottom: 5%;
        left: 4%;
        height: 3px;
        width: 95px;
      }
  }
  
  @media (min-width: 922px) and (orientation: landscape) {
    .faq-header-text-underline {
        background-color: red;
        position: absolute;
        bottom: 5%;
        left: 4%;
        height: 3px;
        width: 135px;
      }
  }

  @media (min-width: 1200px) and (orientation: portrait) {
    .faq-header-text-underline {
        background-color: red;
        position: absolute;
        bottom: 5%;
        left: 4%;
        height: 3px;
        width: 115px;
      }
  }
  
  @media (min-width: 1200px) and (orientation: landscape) {
    .faq-header-text-underline {
        background-color: red;
        position: absolute;
        bottom: 5%;
        left: 4%;
        height: 3px;
        width: 125px;
      }
  }
  

  @media (min-width: 1400px) and (orientation: portrait) {
    .faq-header-text-underline {
        background-color: red;
        position: absolute;
        bottom: 5%;
        left: 4%;
        height: 3px;
        width: 115px;
      }
  }
  
  @media (min-width: 1400px) and (orientation: landscape) {
    .faq-header-text-underline {
        background-color: red;
        position: absolute;
        bottom: 5%;
        left: 4%;
        height: 3px;
        width: 115px;
      }
  }
  
  