.HeroContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 60vh;
}

.ShMainLogo {
  max-width: 90%;
  height: auto;
}

@media (min-width: 768px) {
  .ShMainLogo {
    max-width: 80%;
    height: auto;
  }
}

@media (min-width: 992px) {
  .ShMainLogo {
    max-width: 60%;
    height: auto;
  }
}

@media (min-width: 1200px) {
  .ShMainLogo {
    max-width: 35%;
    height: auto;
  }
}

.Date {
  color: white;
  font-size: 1.2rem;
  letter-spacing: 0.2rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

@media (min-width: 768px) and (orientation: landscape) {
  .Date {
    color: white;
    font-size: 1rem;
    letter-spacing: 0.2rem;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
  }
}

@media (min-width: 768px) and (orientation: portrait) {
  .Date {
    color: white;
    font-size: 1.5rem;
    letter-spacing: 0.2rem;
    font-weight: bold;
  }
}

@media (min-width: 992px) {
  .Date {
    color: white;
    font-size: 1.6rem;
    letter-spacing: 0.2rem;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
  }
}

@media (min-width: 1200px) {
  .Date {
    color: white;
    font-size: 1.7rem;
    letter-spacing: 0.2rem;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
  }
}

.Location {
  color: white;
  font-size: 1.2rem;
  letter-spacing: 0.2rem;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

@media (min-width: 768px) and (orientation: landscape) {
  .Location {
    color: white;
    font-size: 0.75rem;
    letter-spacing: 0.2rem;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
  }
}

@media (min-width: 768px) and (orientation: portrait) {
  .Location {
    color: white;
    font-size: 1.25rem;
    letter-spacing: 0.2rem;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
  }
}

@media (min-width: 992px) {
  .Location {
    color: white;
    font-size: 1.5rem;
    letter-spacing: 0.2rem;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
  }
}

@media (min-width: 1200px) {
  .Location {
    color: white;
    font-size: 1.6rem;
    letter-spacing: 0.2rem;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
  }
}

.MLHLogo-holder {
  width: 5%;
  height: auto;
  position: absolute;
  right: 5%;
  z-index: 1;
  opacity: 0;
  top:0px;
}

@media (min-width: 1200px) and (orientation: landscape) {
  .MLHLogo-holder {
    opacity: 1;
    right: 0%
    
  }
}

@media (min-width: 1313px) and (orientation: landscape) {
  .MLHLogo-holder {
    right: 3%
  }
}



@media (min-width: 1400px) and (orientation: landscape) {
  .MLHLogo-holder {
    right: 0%;

  }
} 

@media (min-width: 1420px) and (orientation: landscape) {
  .MLHLogo-holder {
    right: 0%;

  }
} 

@media (min-width: 1425px) and (orientation: landscape) {
  .MLHLogo-holder {
    right: 0.25%;

  }
} 

@media (min-width: 1450px) and (orientation: landscape) {
  .MLHLogo-holder {
    right: 0.75%;

  }
} 

@media (min-width: 1500px) and (orientation: landscape) {
  .MLHLogo-holder {
    right: 1%;

  }
} 

@media (min-width: 1525px) and (orientation: landscape) {
  .MLHLogo-holder {
    right: 3%;

  }
} 

@media (min-width: 1550px) and (orientation: landscape) {
  .MLHLogo-holder {
    right: 4%;

  }
} 

@media (min-width: 1600px) and (orientation: landscape) {
  .MLHLogo-holder {
    right: 5%;

  }
} 

.ScrollIndicator {
  width: 3rem;
  height: auto;
  position: absolute;
  top: 95%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 768px) and (orientation: portrait) {
  .ScrollIndicator {
    width: 3rem;
    height: auto;
    position: absolute;
    top: 95%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (min-width: 992px) {
  .ScrollIndicator {
    width: 3rem;
    height: auto;
    position: absolute;
    top: 95%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.scroll {
  color: red;
  font-size: 1.2rem;
  letter-spacing: 0.2rem;
  font-weight: bold;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Montserrat", sans-serif;
}

@media (min-width: 650px) and (orientation: landscape) {
  .scroll {
    color: red;
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
    font-weight: bold;
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Montserrat", sans-serif;
  }
}

@media (min-width: 1024px) and (orientation: landscape) {
  .scroll {
    color: red;
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
    font-weight: bold;
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Montserrat", sans-serif;
  }
}